.landing-container {
  height: 100%;

  &__photo {
    background-color: $grey3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    background-image: url('../../images/logo-nma.svg');
  }

  .sign-in-container {
    padding: 0 $size16;

    &__title {
      font-size: $size20;
      text-align: center;
    }

    &__field {
      margin: $size32 0px;
    }

    &__button {
      margin: $size32 0 0;
    }

    .powered-by {
      text-align: right;
      font-size: $size12;
      margin-top: $size16;
      font-style: italic;
    }
  }
}
