.app-container {
  height: 100vh;
}

.app-layout {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header-container {
    display: flex;

    &__excel {
      display: flex;
      justify-content: center;
      padding: $size12;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }

  .sidebar-container {
    display: flex;
    height: 100%;
    padding-bottom: $size24;

    .outlet-container {
      width: calc(100% - 73px);

      @media screen and (max-width: 450px) {
        width: calc(100% - 57px);
      }
    }
  }

  .footer-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: $size24;
    border-top: 6px solid $red;
    min-height: 72px;

    &__title {
      font-size: $size12;
    }

    @media screen and (max-width: 500px) {
      & > div:first-child {
        flex-direction: column;
      }
    }
  }
}

.component-wrapper {
  height: 90vh;
  width: 100%;
}

.outlet-wrapper {
  height: 100%;
  & > div:first-child {
    height: 100%;
  }
}
