.filters-container {
  margin-bottom: $size16;
  margin-top: $size16;
  padding: $size16;
  width: 50%;

  &__group {
    display: flex;
    align-items: center;
    border: 1px solid $grey1;
    border-radius: $size8;
  }

  &__menu {
    border-right: 1px solid $grey1 !important;
    margin-right: $size8;
  }

  &__button {
    background-color: #fff !important;
    color: $black !important;
    margin: $size8 !important;
    width: max-content;
  }

  &__input {
    padding-right: $size8;
    width: 100%;
  }

  &__icon {
    margin-right: $size8;
    cursor: pointer;
    color: $primary;
  }

  @media screen and (max-width: 500px) {
    width: 80%;
  }
}
