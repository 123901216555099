.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.text-center {
  text-align: center;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.mr-1 {
  margin-right: $size8;
}

.mr-2 {
  margin-right: $size16;
}

.mr-3 {
  margin-right: $size24;
}

.mr-4 {
  margin-right: $size32;
}

.mb-1 {
  margin-bottom: $size8;
}

.mb-2 {
  margin-bottom: $size16;
}

.mb-3 {
  margin-bottom: $size24;
}

.mt-1 {
  margin-top: $size8;
}

.mt-2 {
  margin-top: $size16;
}

.mt-3 {
  margin-top: $size24;
}

.text-semibold {
  font-weight: 600;
}

.font-14 {
  font-size: 14px;
}

.height-90vh {
  height: 90vh;
}