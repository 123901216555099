.dialog-container {
  &__actions {
    padding: $size24 !important;
    & button:last-child {
      margin-left: $size16 !important;
    }
  }
}

.delete-dialog {
  &__warning {
    border-radius: 50%;
    background-color: $lightRed2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $size8;
    margin-bottom: $size24;
    width: $size40;
    height: $size40;
  }
}
