.table-list {
  height: 90%;

  &__container {
    height: 75vh;
  }

  &__row {
    &:hover {
      .table-row-action {
        display: flex;
      }
    }
    .table-row-action {
      position: absolute;

      top: 25%;
      border-radius: 50%;
      display: none;
      justify-content: center;
      align-items: center;
      padding: $size4;
      cursor: pointer;
      width: 28px;
      height: 28px;
      box-shadow: $shadow;

      &:active {
        transform: scale(0.8);
      }
    }

    .table-row-edit {
      background-color: $yellow1;
      right: $size56;
      &:hover {
        background-color: $yellow2;
      }
    }

    .table-row-delete {
      background-color: $white;
      right: $size24;
      &:hover {
        background-color: $grey2;
      }
    }
  }

  &__navigate {
    cursor: pointer;

    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
}
