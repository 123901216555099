html {
  font-size: 100%;

  @media screen and (max-width: 1024px) {
    font-size: 90%;
  }

  @media screen and (max-width: 500px) {
    font-size: 80%;
  }
}
