.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  padding: $size24;

  &__right-side {
    padding: $size24;
    margin: $size24;
    box-shadow: $shadow;
    border-radius: 8px;

    .title {
      color: $grey4;
      font-size: $size20;
      font-weight: 700;
      margin-bottom: $size8;
    }

    .route-tiles {
      display: flex;
      flex-wrap: wrap;

      .route-item {
        border: 2px solid $primary;
        border-radius: 8px;
        padding: $size16;
        margin: $size16 $size16 0 0;
        width: 40%;
        cursor: pointer;

        @media screen and (max-width: 1000px) {
          width: auto;
        }

        .label {
          font-weight: 600;
        }

        .sublabel {
          font-size: $size14;
          padding-left: $size8;
        }

        &:hover {
          transform: scale(105%);
        }
        &:active {
          transform: scale(100%);
        }
      }

      @media screen and (max-width: 1000px) {
        flex-direction: column;
      }
    }

    @media screen and (max-width: 500px) {
      width: 100%;
      margin: 0 0 $size16 0;
    }
  }

  &__left-side {
    margin: $size24;
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    overflow-x: auto;
    padding: $size16;
  }
}
