// Palette
$primary: #3b59c4;
$lightBlue: #4cc7ec;
$red: #ef3340;
$lightRed: #f4717a;
$lightRed2: #f7a1a6;
$black: #0a0a0a;
$grey1: #acacac;
$grey2: #f2f2f2;
$grey3: #0000003b;
$grey4: #919492;
$yellow1: #fedd00;
$yellow2: #ecd116;
$white: #fff;

// $shadow: 0px 0px 7px 1px #acacac;
$shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
  0px 1px 18px 0px rgb(0 0 0 / 12%);

// Typography
$size4: 0.24rem;
$size8: 0.5rem;
$size12: 0.75rem;
$size14: 0.875rem;
$size16: 1rem;
$size20: 1.25rem;
$size24: 1.5rem;
$size32: 2rem;
$size40: 2.5rem;
$size56: 3.5rem;
$size85: 5.3125rem;
$size120: 7.5rem;
