.job-dialog {
  padding-top: $size24;
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: $size24;
  }

  &__field {
    width: 100%;
  }
}

.jobs-list {
  &__navigate {
    cursor: pointer;

    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
}

.selected-job {
  display: flex;
  flex-direction: column;
  height: 95%;

  &__upper-section {
    display: flex;
    flex-direction: column;
    flex: 3;
    padding: $size24;

    @media screen and (max-width: 500px) {
      & > div:first-child {
        flex-direction: column;
      }
    }
  }

  &__bottom-section {
    flex: 1;
    border-top: 1px solid $grey1;
    padding-left: $size24;
    padding-right: $size24;
  }

  &__totals {
    padding: $size24;
    border-top: 1px solid $grey1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 500px) {
      flex-direction: column;

      & > div:first-child {
        margin-bottom: $size16;
      }
    }

    .totals-label {
      min-width: 270px;

      @media screen and (max-width: 500px) {
        min-width: 180px;
      }
    }

    .consulting-clickable {
      color: $primary;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__employees-dropdown {
    width: 30%;

    @media screen and (max-width: 500px) {
      width: 50%;
    }
  }

  &__calendar {
    display: flex;
    border: 1px solid $grey1;
    overflow-x: auto;

    & > div:not(:last-child) {
      border-right: 1px solid $grey1;
    }

    & .calendar-month {
      font-size: $size14;
      padding: $size8;
      border-bottom: 1px solid $grey1;
      min-width: $size85;
    }

    & .error-month {
      color: $red;
      font-weight: 600;
    }

    & .calendar-input {
      &.with-border {
        border-bottom: 1px solid $grey1;
        min-height: $size32;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: $size16;
      }

      & > input {
        text-align: center;
      }
    }
  }

  .external-employee-container {
    width: 10%;

    @media screen and (max-width: 500px) {
      width: 50%;
    }

    & > div:first-child {
      border: 1px solid $grey3;
      border-radius: 4px;
      &::before {
        border: none;
      }
      & > input {
        padding: $size8;
      }
      &::after {
        border: none;
      }
    }
  }
}

.selected-consultings-dialog {
  .consultings-list {
    & > div:not(:last-child) {
      margin-bottom: $size8;
    }
  }

  .column-titles {
    border-bottom: 1px solid $grey1;
    margin-bottom: $size16;
  }

  .consulting-column {
    width: 33%;
  }

  .delete-consulting {
    padding: $size4;
    border-radius: 50%;
    display: flex;
    justify-content: flex-end;

    & > svg {
      cursor: pointer;

      &:hover {
        color: $lightRed;
      }

      &:active {
        transform: scale(0.8);
      }
    }
  }

  .disable-delete {
    pointer-events: none;
    opacity: 0.5;
  }
}

.date-dialog-container {
  margin: $size24 0;

  .choose-employee {
    & > div:first-child {
      min-width: 50%;
    }
  }

  &__calendar {
    & > div:first-child {
      justify-content: space-between;
    }
  }
}

.calendar-titles {
  padding-top: $size40;
  margin-right: $size16;

  & > div {
    font-size: $size12;
    min-height: $size32;
    display: flex;
    align-items: center;
  }
}
