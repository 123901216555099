.employee-dialog {
  padding-top: $size24;
  width: 100%;
  & > div:not(:last-child) {
    margin-bottom: $size24;
  }

  &__field {
    width: 100%;
  }

  &__add-more-container {
    width: fit-content;
    cursor: pointer;

    &:active {
      .employee-dialog__add-more {
        transform: scale(0.8);
      }
    }
  }

  &__add-more {
    background-color: $yellow1;
    border-radius: 50%;
    margin-right: $size16;
    padding: $size4;
    box-shadow: $shadow;
  }
}

.selected-employee {
  margin: $size24;
  height: 100%;
  &__card-details {
    box-shadow: $shadow;
    border-radius: $size8;
    padding: $size16;

    & > div:not(:last-child) {
      margin-bottom: $size8;
    }

    .detail-label {
      min-width: $size120;
    }
  }

  &__jobs {
    margin-top: $size24;

    .jobs-participating {
      display: flex;
      justify-content: space-between;

      div {
        flex: 1;
      }

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }
    }

    .job-navigate {
      cursor: pointer;

      &:hover {
        color: $primary;
        text-decoration: underline;
      }
    }
  }
}
